<template>
  <div class="debug-obs mb-3">
    <h4 class="text-white">OBS CONTROLLER</h4>
    <button class="btn btn-secondary mr-1" @click="handleTerry">Terry Talking Head</button>
    <button
      class="btn btn-secondary mr-1"
      :class="{ 'btn-danger': isTerryDanceEnabled }"
      @click="handleTerryDance"
    >
      Terry Dance
    </button>
    <button class="btn btn-secondary mr-1" @click="handleTerry">Terry Props</button>
    <button class="btn btn-secondary mr-1" @click="handleTerry">Terry Raid</button>
    <button class="btn btn-secondary mr-1" @click="handleTerry">Terry Convo</button>
  </div>
</template>

<script>
  import OBSWebSocket, { EventSubscription } from "obs-websocket-js";

  const obs = new OBSWebSocket();

  export default {
    name: "DebugOBS",
    data() {
      return {
        isTerryDanceEnabled: null,
      };
    },
    async created() {
      // 1. https://github.com/obsproject/obs-websocket/blob/master/docs/generated/protocol.md#getscenelist
      // 2. https://github.com/obs-websocket-community-projects/obs-websocket-js

      try {
        const { obsWebSocketVersion, negotiatedRpcVersion } = await obs.connect(
          "ws://192.168.1.11:4455",
          "2EFIfb2TDrqVK8oz",
          {
            rpcVersion: 1,
          }
        );

        console.log(
          `Success! Connected to OBS websocket server (obsWebSocketVersion:${obsWebSocketVersion}) (using RPC ${negotiatedRpcVersion})`
        );

        this.handleTerryDance({ isInit: true });

        /**
         * WORKS TO GET
         */

        // const { currentProgramSceneName: activeScene } = await obs.call(
        //   "GetCurrentProgramScene",
        //   {}
        // );

        /*
        const sceneItemListResponse = await obs.call("GetSceneItemList", {
          sceneName: activeScene,
          filter: {
            "item-type": "source",
          },
        });

        console.log({ activeScene, sceneItemListResponse });
*/

        // GetGroupList
        // const terrySources = await obs.call("GetSceneItemList", {
        //   sceneName: "scn_nst: Character__Terry",
        //   filter: {
        //     "item-type": "group",
        //     // "item-name": "Terry Sequence 01", // Name of the group
        //   },
        // });

        // GET SOURCE ACTIVE -- WORKS!
        // const terrySources = await obs.call("GetSourceActive", {
        //   sourceName: "Terry01 - Idle",
        // });

        // GET GROUP SCENE ITEM LIST (TERRY VIDS) -- WORKED BUT I REMOVED THE GROUP!
        // const terrySources = await obs.call("GetGroupSceneItemList", {
        //   sceneName: "Terry Sequence 01",
        // });

        // console.log({ terrySources });

        //
      } catch (error) {
        console.error("Failed to connect", error.code, error.message);
      }
    },
    methods: {
      async handleTerry() {
        const { sceneItemEnabled, sceneItemId } = await this.getSceneSource();

        // UPDATE SOURCE
        await obs.call("SetSceneItemEnabled", {
          sceneName: "scn: MAIN_CONTROLLER",
          sceneItemId,
          sceneItemEnabled: !sceneItemEnabled,
        });

        console.log(`Visibility of src toggled to ${!sceneItemEnabled}!`);
      },

      async handleTerryDance({ isInit = null }) {
        const { sceneItemEnabled, sceneItemId } = await this.getSceneSource(
          "scn_nst: Character__Terry",
          "Terry01 - Dance"
        );

        // UPDATE SOURCE
        if (!isInit) {
          await obs.call("SetSceneItemEnabled", {
            sceneName: "scn_nst: Character__Terry",
            sceneItemId,
            sceneItemEnabled: !sceneItemEnabled,
          });
        }

        this.isTerryDanceEnabled = isInit ? sceneItemEnabled : !sceneItemEnabled;

        console.log(`Visibility of src toggled to ${!sceneItemEnabled}!`);
      },

      async getSceneSource(sceneName = "scn: MAIN_CONTROLLER", sourceName = "scn_grp: Terry") {
        const { sceneItems } = await obs.call("GetSceneItemList", {
          sceneName,
        });

        const sourceItem = sceneItems.find((item) => item.sourceName === sourceName);
        return sourceItem;
      },
    },
  };
</script>

<style lang="scss" scoped></style>
